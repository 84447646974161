import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import PassMarkSearch from 'components/passMarkSearch';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "used-select"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/others/usedselect.png"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    cusedpc: file(relativePath: { eq: "cover/others/usedpc.png"}) { ...eyecatchImg },
    cused: file(relativePath: { eq: "cover/others/used.png"}) { ...eyecatchImg },
    cusedshop: file(relativePath: { eq: "cover/rank/usedshop.png"}) { ...eyecatchImg },
    cssd_size: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
    coffice: file(relativePath: { eq: "cover/pcparts/office.PNG"}) { ...eyecatchImg },
    battery: file(relativePath: { eq: "usedpc/battery.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`中古パソコンの選び方2024 おすすめのスペックや狙い目の機種`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="中古パソコンの選び方" mdxType="Image" />
    <p>{`中古パソコン市場において独特なのは`}<strong parentName="p"><em parentName="strong">{`需給の不均衡により、特定の機種のみ非常に安く売られていること`}</em></strong>{`である。まずは中古特有の市場について、すなわちどのような機種がお買い得なのかを頭に入れよう。`}</p>
    <p>{`その上で、お買い得商品の中から`}<strong parentName="p"><em parentName="strong">{`どのようなスペックを選ぶかを中古特有の失敗しやすいポイント(CPUやOS、オフィス製品等)に留意しつつ決めていけば、価格的にも品質的にも納得できる買い物ができる`}</em></strong>{`だろう。`}</p>
    <p>
なお、基本的なパソコンの選び方や用語は解説しないので<Link to="/" mdxType="Link">トップページ</Link>などを読んで学習してほしい。それでは解説を始める。
    </p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "型落ち法人用PCが最もお得",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%9E%8B%E8%90%BD%E3%81%A1%E6%B3%95%E4%BA%BA%E7%94%A8PC%E3%81%8C%E6%9C%80%E3%82%82%E3%81%8A%E5%BE%97",
        "aria-label": "型落ち法人用PCが最もお得 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`型落ち法人用PCが最もお得`}</h2>
    <p>{`まずは中古パソコン市場について正しく理解したい。下記の表はとあるパソコンの、４年前に新品で売られていた時の価格と４年後の中古での価格、値下がり率を表にしたものである。`}</p>
    <p>{`４年後の値下がり率には機種により非常に大きな差があることが分かるだろうか？`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th>機種</th>
      <th>新品時価格</th>
      <th>４年後中古価格</th>
      <th>割引率</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>ゲーミング(PC工房)</td>
      <td>208000</td>
      <td>169800</td>
      <td>18%</td>
    </tr>
    <tr>
      <td>MacBook Air</td>
      <td>114800</td>
      <td>72800</td>
      <td>36%</td>
    </tr>
    <tr>
      <td>個人向け(Dell)</td>
      <td>130000</td>
      <td>57800</td>
      <td>55%</td>
    </tr>
    <tr>
      <td>法人向けノート(HP)</td>
      <td>151800</td>
      <td>36300</td>
      <td>76%</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`これは単なる一例にすぎず、例外もあるだろうが、おおよそ次のようなことが言える。`}</p>
    <p>{`まずゲーミングPCは単価が高く、中古で購入しようとする層が多いことから中古パソコン需要が供給を大幅に上回り、中古での購入にあまりメリットがない。`}</p>
    <p>{`次にMacBookはiPhoneと同様の話になるが、ブランド力が高く、中古でもよいので欲しいという層が大量にいることため、これまた需要と供給のバランスが悪い。逆に最終的に売ろうと考えて新品Macbookを購入するのは正しい選択である。`}</p>
    <p>{`次に個人向けPC。例はDellの一体型モデルの価格である。一体型は基本的には家庭で純粋に楽しむためのエンターテイメントモデルであり、法人向けには採用されない。純粋に個人の売り手と買い手ということで需給が均衡し、`}<strong parentName="p"><em parentName="strong">{`個人向けモデルの価格は４年で半額程度`}</em></strong>{`になる。`}</p>
    <p>{`最後に法人向けノートである。法人向けのPCは減価償却を終えた４年後程度のパソコンを業者が大量に回収して売りに出すため最も供給が多くなるが、一方で個人需要は一定である。このため`}<strong parentName="p"><em parentName="strong">{`法人向けPCの型落ち版は供給過多となり70%を超える割引率となる`}</em></strong>{`のである。`}</p>
    <p>{`ちなみに法人向けであれ個人向けであれ、中古パソコンはOSのクリーンインストールがなされ、Windowsの基本ソフトだけの状態となって出荷されるため、`}<strong parentName="p"><em parentName="strong">{`中古パソコンには法人も個人も存在しない`}</em></strong>{`。`}</p>
    <p>
よって<strong><b>市場面から言える結論としては、法人向けPCを中心にパソコンを探せ</b></strong>ということになる。具体的にどこから探せば良いかは本記事最後の<Link to="#中古パソコンのおすすめショップ" mdxType="Link">中古パソコンのおすすめショップ</Link>で説明する。
    </p>
    <h3 {...{
      "id": "おすすめのメーカーは何か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%81%AF%E4%BD%95%E3%81%8B%EF%BC%9F",
        "aria-label": "おすすめのメーカーは何か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめのメーカーは何か？`}</h3>
    <p>{`上記の話により、メーカーとしては法人向けとして多く出回っているメーカーがコスパが高くなりおすすめである。`}</p>
    <p>{`具体的には`}<strong parentName="p">{`海外メーカーならば`}<em parentName="strong">{`Lenovo`}</em>{`、`}<em parentName="strong">{`Dell`}</em>{`、`}<em parentName="strong">{`HP`}</em>{`の３社、日本メーカーならば`}<em parentName="strong">{`富士通`}</em>{`、`}<em parentName="strong">{`Dynabook(旧東芝)`}</em>{`、`}<em parentName="strong">{`パナソニック(Let's Note)`}</em>{`、`}<em parentName="strong">{`Vaio`}</em>{`、`}<em parentName="strong">{`NEC`}</em></strong>{`である。`}</p>
    <p>{`個人的には、`}<strong parentName="p"><em parentName="strong">{`新品では３０万円近くして手が届かないようなLet's NoteやVaioパソコンの型落ちが７割引き程度で買えるの`}</em></strong>{`が非常に嬉しいポイントである。`}</p>
    <h3 {...{
      "id": "スペックは４、５年前ハイスペックPCがおすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AF%EF%BC%94%E3%80%81%EF%BC%95%E5%B9%B4%E5%89%8D%E3%83%8F%E3%82%A4%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AFPC%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "スペックは４、５年前ハイスペックPCがおすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スペックは４、５年前ハイスペックPCがおすすめ`}</h3>
    <p>{`パソコンの減価償却は４年である。すなわち企業として一括で購入するPCとして、経費の観点や性能の観点も含めて４年で買い替えを行う企業が多い。`}</p>
    <p>{`私が勤めている会社でも、新品から４年が経過すれば新しいパソコンに乗り換えてもよいルールとなっている。`}</p>
    <p>{`すなわち`}<strong parentName="p"><em parentName="strong">{`需給面では４～５年程度経過したハイスペックな法人PCが中古市場には大量に出回っている`}</em></strong>{`ことになり、これを３～６万円程度で購入するのが狙いどころではないかと思われる。`}</p>
    <p>{`バッテリーの観点でも４～５年前であるならあ概ね８割程度は残っていると考えて良い。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`デスクトップの場合はバッテリーは不要であり、スペックもパワフルなので６～７年程度経過していても十分に通用する`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "中古パソコンのスペック選びの実際",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E9%81%B8%E3%81%B3%E3%81%AE%E5%AE%9F%E9%9A%9B",
        "aria-label": "中古パソコンのスペック選びの実際 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンのスペック選びの実際`}</h2>
    <p>{`おおよそ狙い目の機種は理解していただけたと思うが、中古パソコンの具体的なスペックの選び方を次からは解説したい。`}</p>
    <p>{`特に種類が多く性能の判別が難しいCPU、サポート期間を意識しなければならないOS、中古であるのに新品と同様の価格のOffice製品は特に注意が必要である。`}</p>
    <h3 {...{
      "id": "CPUはベンチマーク結果とその目安を活用",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AF%E3%83%99%E3%83%B3%E3%83%81%E3%83%9E%E3%83%BC%E3%82%AF%E7%B5%90%E6%9E%9C%E3%81%A8%E3%81%9D%E3%81%AE%E7%9B%AE%E5%AE%89%E3%82%92%E6%B4%BB%E7%94%A8",
        "aria-label": "CPUはベンチマーク結果とその目安を活用 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUはベンチマーク結果とその目安を活用`}</h3>
    <p>{`CPUはパソコンの処理速度の根幹をなす重要なパーツであり、性能が低いと処理に時間が掛かったり、ローディング画面が出続けるなど悪影響が大きいため、慎重に選びたい。`}</p>
    <p>{`パソコン用のCPUメーカーは主にIntelとAMD２社のみであり、`}<strong parentName="p"><em parentName="strong">{`IntelはCore iシリーズ、AMDはRyzenシリーズを展開`}</em></strong>{`している。`}</p>
    <p>{`Core iシリーズはラインナップが上位のものからCore i9/i7/i5/i3、RyzenもRyzen 9/7/5/3と分かれナンバリングされているが、それ以外にも設計の新しさを表す世代の違いなどにより`}<strong parentName="p"><em parentName="strong">{`1000を超えるCPUが誕生している`}</em></strong>{`。`}</p>
    <p>{`性能差も大きく、たとえば`}<strong parentName="p"><em parentName="strong">{`単にCore i5といっても、10年前のCore i5と最新のCore i5では雲泥の差があるため`}</em></strong>{`、特に中古パソコン選びでは各CPUの性能を正しく把握する必要がある。`}</p>
    <p>{`そこでおすすめなのが、`}<strong parentName="p"><em parentName="strong">{`PassMarkというCPUの総合性能を指数化したベンチマークテストの結果`}</em></strong>{`を利用することである。`}</p>
    <p>{`スコアの目安とチェックツール(`}<em parentName="p">{`PASSMARK SEARCH`}</em>{`)を下に用意しているので、中古パソコンのスペック表などからCPUの型番情報を抜き出して性能をチェックして欲しい。2000点以下のカスなCPU搭載の中古パソコンは選ばないように気を付けたい。`}</p>
    <PassMark mdxType="PassMark" />
    <PassMarkSearch mdxType="PassMarkSearch" />
    <p>{`より詳しくは下記のCPU比較表でも確認できる。`}</p>
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h3 {...{
      "id": "WindowsはCore-iの第８世代以上ならば11にアップグレードが可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Windows%E3%81%AFCore-i%E3%81%AE%E7%AC%AC%EF%BC%98%E4%B8%96%E4%BB%A3%E4%BB%A5%E4%B8%8A%E3%81%AA%E3%82%89%E3%81%B011%E3%81%AB%E3%82%A2%E3%83%83%E3%83%97%E3%82%B0%E3%83%AC%E3%83%BC%E3%83%89%E3%81%8C%E5%8F%AF%E8%83%BD",
        "aria-label": "WindowsはCore iの第８世代以上ならば11にアップグレードが可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WindowsはCore iの第８世代以上ならば11にアップグレードが可能`}</h3>
    <p>{`Windows 11へのUpdate用件として、セキュリティやスペック上の制約を公式に設けており、それによると`}<strong parentName="p"><em parentName="strong">{`Core iシリーズは第８世代以上、Ryzenシリーズならば型番が2000以上`}</em></strong>{`である。`}</p>
    <p>{`例えばCore i5 `}<em parentName="p">{`8`}</em>{`250UというCPUなら第８世代である。8000より番号が大きいか、`}<em parentName="p">{`13`}</em>{`60P(13世代)など一周回って小さくなり、最初の２文字が10台だと10世代以上である。`}</p>
    <p>{`ちなみに第８世代未満のCPUも動作上問題ないとしてWindows 11のパソコンを売りに出している中古パソコンもある。ただ、`}<strong parentName="p"><em parentName="strong">{`基本的にはサポート対象外なのでおすすめはしない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "サポート期限切れOSを使い続けて問題はないか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E6%9C%9F%E9%99%90%E5%88%87%E3%82%8COS%E3%82%92%E4%BD%BF%E3%81%84%E7%B6%9A%E3%81%91%E3%81%A6%E5%95%8F%E9%A1%8C%E3%81%AF%E3%81%AA%E3%81%84%E3%81%8B%EF%BC%9F",
        "aria-label": "サポート期限切れOSを使い続けて問題はないか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポート期限切れOSを使い続けて問題はないか？`}</h3>
    <p>{`Windows 10はサポート期間が2025年末頃に終了するため、Updateできるパソコンを購入すると良いが、実質的な観点ではOSのサポート期間が切れたからといって直ちにセキュリティが脆弱になるわけではない。`}</p>
    <p>{`むしろ市場で10年近く鍛えられたOSはセキュリティ的には強固であるので、個人で使う上では`}<strong parentName="p"><em parentName="strong">{`サポート期間終了後少なくとも３年は問題はない`}</em></strong>{`。`}</p>
    <p>{`これについては下記の記事で詳しく解説しているので興味のある方は確認してほしい。`}</p>
    <Card {...props} title="中古パソコンの危険性は？セキュリティリスクとその対策" description="中古パソコンに特有のセキュリティリスクを取り上げ、ウイルス対策やOSの選び方、購入場所のおすすめなど幅広く解説。" image="cused" path="/used/" mdxType="Card" />
    <h3 {...{
      "id": "メモリは8GBとSSDは256GB、HDDは避ける",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF8GB%E3%81%A8SSD%E3%81%AF256GB%E3%80%81HDD%E3%81%AF%E9%81%BF%E3%81%91%E3%82%8B",
        "aria-label": "メモリは8GBとSSDは256GB、HDDは避ける permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは8GBとSSDは256GB、HDDは避ける`}</h3>
    <p>{`高度な3Dゲームや、4Kでの映像編集などを行なわないならば、`}<strong parentName="p"><em parentName="strong">{`基本的にメモリは8GBあれば良い`}</em></strong>{`だろう。4GBだとWebサイトの閲覧でも苦しい時があるので中古であっても8GBは確保したい。`}</p>
    <p>{`またストレージはHDDではなく`}<strong parentName="p"><em parentName="strong">{`起動速度や故障率の観点から特にSSDをおすすめしたい`}</em></strong>{`。また容量は256GB程度あると安心材料である。詳しくは下記を参考にしてほしい。`}</p>
    <Card {...props} title="【256GB,512GB,1TB】SSDの容量のおすすめは何か？" description="ストレージの容量を決めかねている場合に参考になる情報を網羅" image="cssd_size" path="/ssd/" mdxType="Card" />
    <h3 {...{
      "id": "Microsoft-Officeは初期搭載すべきではない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Microsoft-Office%E3%81%AF%E5%88%9D%E6%9C%9F%E6%90%AD%E8%BC%89%E3%81%99%E3%81%B9%E3%81%8D%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84",
        "aria-label": "Microsoft Officeは初期搭載すべきではない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Microsoft Officeは初期搭載すべきではない`}</h3>
    <p>{`大学などに行くと20年以上使っているようなパソコンを多く見るが、用途が非常に限られるため問題がないだけであり、一般的に性能面を考慮したときに寿命は10年程度である。`}</p>
    <p>{`当然中古だと残存寿命が新品と比べ短くなるわけだが、プリインストールされたオフィス製品(OEM版)はそのパソコンにしか使えないため、`}<strong parentName="p"><em parentName="strong">{`寿命が短い中古パソコンに対してOEM版のマイクロソフトオフィスを入れることは割りに合わない`}</em></strong>{`。`}</p>
    <p>{`そこでおすすめなのは`}<a parentName="p" {...{
        "href": "https://amzn.to/3X7fiob",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`オンラインコード版のオフィス`}</a>{`である。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>OEM板</th>
      <th>オンラインコード版</th>
      <th>パッケージ版</th>
      <th>Microsoft365</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>適用範囲</td>
      <td>１台限り</td>
      <td>２台まで</td>
      <td>２台まで</td>
      <td>無制限</td>
    </tr>
    <tr>
      <td>料金</td>
      <td>25000円程度</td>
      <td>35000円程度</td>
      <td>40000円程度</td>
      <td>月額1000円程度</td>
    </tr>
    <tr>
      <td>備考</td>
      <td align="left">購入時のパソコン以外では使用できない</td>
      <td align="left">ライセンスの他パソコンへの移行が可能</td>
      <td align="left">ライセンスの他パソコンへの移行が可能</td>
      <td align="left">Skype月60分、クラウドストレージ1TBが無料で付随</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`オンラインコード版は２台のパソコンにインストールできる上、ライセンスの他パソコンへの移行も可能なため、実質同時に２台まで永久に使えることになる。`}</p>
    <p>{`故障確率を考えると断然こちらを購入すべきである。また、物理的なDVDが欲しい場合はパッケージ版もあるが、数千円高くなるので`}<strong parentName="p"><em parentName="strong">{`オンラインコード版がおすすめ`}</em></strong>{`である。`}</p>
    <p>{`なお、そもそもマイクロソフトオフィスが必要か？という疑問については下記の記事を参考にして欲しい。`}</p>
    <Card {...props} title="オフィスソフトは必要か？" description="搭載するか否か、互換ソフトやOffice365等で悩んでいる人へ情報をまとめています。" image="coffice" path="/office/" mdxType="Card" />
    <h3 {...{
      "id": "バッテリー残存量を推定する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%90%E3%83%83%E3%83%86%E3%83%AA%E3%83%BC%E6%AE%8B%E5%AD%98%E9%87%8F%E3%82%92%E6%8E%A8%E5%AE%9A%E3%81%99%E3%82%8B",
        "aria-label": "バッテリー残存量を推定する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`バッテリー残存量を推定する`}</h3>
    <Image {...props} name="battery" alt="バッテリー残存量" mdxType="Image" />
    <p>{`バッテリー残量は非常に判断するのが難しいので、ある程度は運任せとなる。`}</p>
    <p>{`バッテリーが長持ちするようにフル充電は避けていたわり充電を行っていたかなど使用状態に対しては知る由がないため、`}<strong parentName="p"><em parentName="strong">{`判断材料としては経過年数と使用感`}</em></strong>{`のみである。`}</p>
    <p>{`自動車の電池保証によると凡そ`}<strong parentName="p"><em parentName="strong">{`８年間でMAXの70%程度が保証範囲`}</em></strong>{`となっているので中古パソコンもその程度と見積ろう。`}</p>
    <p>{`見た目が判別できる写真が掲載されている場合は、特に`}<strong parentName="p"><em parentName="strong">{`キーボードのテカリがない状態をおすすめ`}</em></strong>{`したい。`}</p>
    <p>{`テカリがあるのはタイピングが力強いか、使い込んでいるかのどちらかであるが、力強い場合はキーが効かなく確率が増すし、使い込んでいたらバッテリーの劣化が心配である。`}</p>
    <p>{`逆に小さな傷などは本当に見た目だけのケースが多いので、`}<strong parentName="p"><em parentName="strong">{`傷やスレのためにB級品扱いされているようなパソコンはお買い得`}</em></strong>{`であるといえる。`}</p>
    <p>{`バッテリー残量を確認など残量を計測して保証してくれている中古パソコンショップもあるのでぜひ活用したい。`}</p>
    <h2 {...{
      "id": "中古パソコンのおすすめショップ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97",
        "aria-label": "中古パソコンのおすすめショップ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンのおすすめショップ`}</h2>
    <p>{`最後に、購入場所についてだが、品質と価格、セキュリティリスク等を総合して考えると優良な中古パソコンショップで購入することがおすすめである。`}</p>
    <p>{`特に`}<strong parentName="p"><em parentName="strong">{`下記の３社はPCを独自のルートで多く保有しており、高い価格競争力も備える`}</em></strong>{`。まずはこの３社から検討してみると効率が良いだろう。`}</p>
    <h3 {...{
      "id": "１位：Qualit---ビジネスクオリティの中古パソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%91%E4%BD%8D%EF%BC%9AQualit---%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%AF%E3%82%AA%E3%83%AA%E3%83%86%E3%82%A3%E3%81%AE%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "１位：Qualit   ビジネスクオリティの中古パソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`１位：Qualit - ビジネスクオリティの中古パソコン`}</h3>
    <Image {...props} name="qualit" alt="Qualit" mdxType="Image" />
    <p>{`まず最もおすすめしたいサイトがこのQualitである。他の中古パソコンと比べ圧倒的なコストパフォーマンスと品質を誇る。`}</p>
    <p>{`横河レンタ・リースという法人向けとして有名なパソコンのレンタルサービスがあり、私が勤めている会社でも利用しているのだが、`}<strong parentName="p"><em parentName="strong">{`レンタル期限を終えて返却されたパソコンを再整備して売りに出している`}</em></strong>{`のである。`}</p>
    <p>{`安値で仕入れ、高値で売るという多くの中古パソコンショップとは`}<strong parentName="p"><em parentName="strong">{`ビジネススキームで一線を画しており、これが圧倒的な価格競争力を実現`}</em></strong>{`している。`}</p>
    <p>{`更に会社として法人向けの厳しいビジネス要求に応えられるよう`}<strong parentName="p"><em parentName="strong">{`バッテリーチェックを含め高いレベルの点検・整備を行なっている`}</em></strong>{`ので品質面で非の打ちどころは無い。`}</p>
    <p>{`法人用にレンタルで使われているという事実は、個人で無理な使われ方をしたパソコンが来ないという点でも安心材料である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" target="_blank" rel="nofollow noopener">Qualit</a>
  <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "２位：PC-WRAP---業界最長３年保証を誇る中古PCの老舗",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%92%E4%BD%8D%EF%BC%9APC-WRAP---%E6%A5%AD%E7%95%8C%E6%9C%80%E9%95%B7%EF%BC%93%E5%B9%B4%E4%BF%9D%E8%A8%BC%E3%82%92%E8%AA%87%E3%82%8B%E4%B8%AD%E5%8F%A4PC%E3%81%AE%E8%80%81%E8%88%97",
        "aria-label": "２位：PC WRAP   業界最長３年保証を誇る中古PCの老舗 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`２位：PC WRAP - 業界最長３年保証を誇る中古PCの老舗`}</h3>
    <Image {...props} name="pcwrap" alt="PCWrap" mdxType="Image" />
    <p>{`東証一部上場の急成長IT企業であるShiftのグループ会社である株式会社エスエヌシーが運営するパソコンショップで、大阪を主な拠点とする。`}</p>
    <p>{`中古PC販売20年以上の老舗であり、年間６万台程度パソコンを再生するトップクラスの取引量を誇る。`}</p>
    <p>{`取引量と効率的な検品のオペレーションからくる`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスに加え、３年保証、当日発送など購入者にとっての安心材料も豊富`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" target="_blank" rel="nofollow noopener">PC WRAP</a>
      <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "３位：JUNKWORLD---激安中古パソコンの殿堂",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%93%E4%BD%8D%EF%BC%9AJUNKWORLD---%E6%BF%80%E5%AE%89%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E6%AE%BF%E5%A0%82",
        "aria-label": "３位：JUNKWORLD   激安中古パソコンの殿堂 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`３位：JUNKWORLD - 激安中古パソコンの殿堂`}</h3>
    <Image {...props} name="junk_world" alt="JUNKWORLD" mdxType="Image" />
    <p>{`株式会社アールキューブというパソコン回収とデータ消去を中心とした事業を行なっている会社が運営している中古パソコンショップ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.pc-kaishu.com/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`パソコン回収.com`}</a>{`などを通じて、法人、個人双方から不要になったパソコンの回収を行なっており、`}<strong parentName="p"><em parentName="strong">{`個人はどんなパソコンであっても無料で引き取ってくれる`}</em></strong>{`。`}</p>
    <p>{`このサービス、あるいは買取を通じて集まったパソコンのうち再利用可能なものを再整備して発売しているサイトがJUNKWORLDである。`}</p>
    <p>{`他社が真似できないユニークな方法でパソコンを集めていおり、年間数万台の販売実績を誇り規模も大きい事から`}<strong parentName="p"><em parentName="strong">{`品揃えや価格競争力は十分に高い`}</em></strong>{`が、ショップ名の通りジャンク品が豊富で品質のバラつきには注意が必要なため、商品写真等には目を配りたい。`}</p>
    <p>{`また、万が一不具合を引き当てた場合は30日と標準的な返品保証もついてくるので活用したい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=888459649" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=888459649" height="1" width="1" border="0" />JUNKWORLD</a>
    </SmallBox>
    <h3 {...{
      "id": "その他購入に関する関連情報",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E8%B3%BC%E5%85%A5%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E9%96%A2%E9%80%A3%E6%83%85%E5%A0%B1",
        "aria-label": "その他購入に関する関連情報 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他購入に関する関連情報`}</h3>
    <p>{`どこで購入するかについては下記の記事で詳しく述べているの気が向けば参考にしてほしい。`}</p>
    <Card {...props} title="中古パソコンを買うならどこがおすすめかのココだけの話" description="中古パソコンを買うならどこがおすすめかを品質と価格の観点から解説します。" image="cusedpc" path="/used-pc/" mdxType="Card" />
    <p>{`また、本ページで良い中古パソコンが見つからなかい場合はより多くの店舗を紹介している下記も参考にしてほしい。`}</p>
    <Card {...props} title="中古パソコンおすすめショップランキング" description="中古パソコンのおすすめショップをランキング形式で紹介。品質とコスパを両立させた優良ショップを厳選します。" image="cusedshop" path="/used-shop/" mdxType="Card" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      